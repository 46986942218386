import axios from 'axios'
import router from './router/index'

function parseJwt(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(
    c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`,
  ).join(''))

  return JSON.parse(jsonPayload)
}

function storeToken(token) {
  localStorage.token = token
  const { data } = parseJwt(token)
  console.log(data)
}

function removeToken() {
  localStorage.token = ''
}

let baseUrl = 'http://localhost:8082/api/'

if (process.env.NODE_ENV === 'production') {
  baseUrl = '/api/'
}

function initiateApi(timeout) {
  const config = {
    baseURL: baseUrl,
    timeout: 10000,
    headers: { Authorization: `bearer ${localStorage.token}` },
  }

  if (timeout) {
    config.timeout = timeout
    console.log(JSON.stringify(config))
  }

  const instance = axios.create(config)

  return instance
}

const api = {

  baseUrl() {
    let url = 'http://localhost:8082/'

    if (process.env.NODE_ENV === 'production') {
      url = '/'
    }

    return url
  },

  login(data, onLoginSuccess) {
    const instance = axios.create({
      baseURL: baseUrl,
      timeout: 30000,
    })

    instance.post('/login-user', data)
      .then(
        response => {
          const { data: { data: { token: tok } } } = response
          storeToken(tok)
          onLoginSuccess()
        },
      )
  },

  logout() {
    removeToken()
    router.push('/pages/login')
  },

  handleCommand(path, data, callback) {
    const apiService = initiateApi()
    apiService.post(path, data)
      .then(response => callback(response))
  },

  campaigns(callback) {
    const apiService = initiateApi()
    apiService.get('/query/list-campaign')
      .then(response => {
        const { data: { data: campaigns } } = response
        callback(campaigns)

        return campaigns
      })
  },

  reportCampaign(campaignID, callback) {
    const apiService = initiateApi()
    apiService.get(`/query/report-campaign?CampaignID=${campaignID}`)
      .then(response => {
        const { data: { data: reports } } = response
        callback(reports)
      })
  },

  videos(callback) {
    const apiService = initiateApi()
    apiService.get('/query/list-video')
      .then(response => {
        const { data: { data: videos } } = response
        callback(videos)

        return videos
      })
  },

  getCampaign(campaignID, callback) {
    const apiService = initiateApi()
    const url = `/query/get-campaign?CampaignID=${campaignID}`
    apiService.get(url)
      .then(response => {
        const { data: { data: campaign } } = response
        callback(campaign)

        return campaign
      })
  },

  addTag(campaignID, tag, callback) {
    this.handleCommand('/add-tag', { CampaignID: campaignID, Tag: tag }, callback)
  },

  removeTag(campaignID, tag, callback) {
    this.handleCommand('/remove-tag', { CampaignID: campaignID, Tag: tag }, callback)
  },

  createCampaign(data, callback) {
    function cb(response) {
      const { data: { data: campaign } } = response

      callback(campaign)
    }

    this.handleCommand('/create-campaign', data, cb)
  },

  addVideo(data, callback) {
    this.handleCommand('/add-video', data, callback)
  },

  setCreative(data, callback) {
    this.handleCommand('/set-creative', data, callback)
  },

  renameCampaign(campaignID, name, callback) {
    const data = {
      CampaignID: campaignID,
      Name: name,
    }
    this.handleCommand('/rename-campaign', data, callback)
  },

  changeReservation(campaignID, period, impression, callback) {
    const data = {
      CampaignID: campaignID,
      Period: period,
      Impression: impression,
    }
    this.handleCommand('/change-reservation', data, callback)
  },

  changeSchedule(campaignID, dateStart, dateEnd, callback) {
    const data = {
      CampaignID: campaignID,
      DateStart: dateStart,
      DateEnd: dateEnd,
    }
    this.handleCommand('/change-schedule', data, callback)
  },

  addCreative(data, callback) {
    this.handleCommand('/add-creative', data, callback)
  },

  removeCreative(data, callback) {
    this.handleCommand('/remove-creative', data, callback)
  },

  activateCampaign(data, callback) {
    this.handleCommand('/activate-campaign', data, callback)
  },

  pauseCampaign(data, callback) {
    this.handleCommand('/pause-campaign', data, callback)
  },

  upload(data, callback, onProgressCallback) {
    const apiService = initiateApi(36000000)
    const formData = new FormData()
    const config = {}

    if (onProgressCallback !== undefined) {
      config.onUploadProgress = progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 10000) / progressEvent.total) / 100
        onProgressCallback(percentCompleted)
      }
    }
    formData.append('file', data.file)
    apiService.post('/upload-media', formData, config)
      .then(response => {
        const { data: { data: media } } = response
        callback(media)
      })
  },

  uploadFile(data, callback, onProgressCallback) {
    const apiService = initiateApi(36000000)
    const formData = new FormData()
    const config = {}

    if (onProgressCallback !== undefined) {
      config.onUploadProgress = progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 10000) / progressEvent.total) / 100
        onProgressCallback(percentCompleted)
      }
    }
    formData.append('file', data.file)
    apiService.post('/upload-media-file', formData, config)
      .then(response => {
        const { data: { data: media } } = response
        callback(media)
      })
  },
}

export default api
